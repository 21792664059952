import { Circle } from "./circle";
import { CircleProgressbarProps } from "./model";

export function CircleProgressbar({ steps,current_step }: CircleProgressbarProps) {
  return (
    <section class="flex">
      {steps.map((step) => {
        return <Circle step_number={step.step_number} step_name={step.step_name} completed={current_step >= step.step_number} is_last={step.is_last} />;
      })}
    </section>
  );
}
